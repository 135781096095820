.custom-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #17275c;
  }
  
  .custom-container {
    display: grid;
    grid-template-columns: repeat(3, 12vmin);
    grid-template-rows: repeat(3, 12vmin);
    gap: 1vmin;
  }
  
  .custom-item {
    background: rgba(255, 255, 255, 0.1);
    display: grid;
    place-items: center;
    border-radius: 4px;
    transition: opacity 0.4s ease;
  }
  
  .custom-container:hover .custom-item {
    opacity: 0.3;
  }
  
  .custom-container:hover .custom-item:hover {
    opacity: 1;
  }
  
  .custom-loader {
    --custom-color: white;
    --custom-size-mid: 6vmin;
    --custom-size-dot: 1.5vmin;
    --custom-size-bar: 0.4vmin;
    --custom-size-square: 3vmin;
    
    display: block;
    position: relative;
    width: 50%;
    display: grid;
    place-items: center;
  }
  
  .custom-loader::before,
  .custom-loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
  }
  
  /* Loader 1 */
  .custom-loader-1::before {
    width: var(--custom-size-mid);
    height: var(--custom-size-mid);
    border: 4px solid var(--custom-color);
    border-top-color: transparent;
    border-radius: 50%;
    animation: custom-loader-1 1s linear infinite;
  }
  
  .custom-loader-1::after {
    width: calc(var(--custom-size-mid) - 2px);
    height: calc(var(--custom-size-mid) - 2px);
    border: 2px solid transparent;
    border-top-color: var(--custom-color);
    border-radius: 50%;
    animation: custom-loader-1 0.6s linear reverse infinite;
  }
  
  @keyframes custom-loader-1 {
    100% {
      transform: rotate(1turn);
    }
  }
  
  /* Loader 2 */
  .custom-loader-2::before,
  .custom-loader-2::after {
    width: var(--custom-size-dot);
    height: var(--custom-size-dot);
    background-color: var(--custom-color);
    border-radius: 50%;
    opacity: 0;
    animation: custom-loader-2 0.8s cubic-bezier(0.2, 0.32, 0, 0.87) infinite;
  }
  
  .custom-loader-2::after {
    animation-delay: 0.3s;
  }
  
  @keyframes custom-loader-2 {
    0%, 80%, 100% {
      opacity: 0;
    }
    
    33% {
      opacity: 1;
    }
    
    0%, 100% {
      transform: translateX(-4vmin);
    }
    
    90% {
      transform: translateX(4vmin);
    }
  }
/* Loader 3 */
.custom-loader-3::before,
.custom-loader-3::after {
  width: var(--custom-size-dot);
  height: var(--custom-size-dot);
  background-color: var(--custom-color);
  border-radius: 50%;
  animation: custom-loader-3 1.2s ease-in-out infinite;
}

.custom-loader-3::before {
  left: calc(50% - 1.6vmin - var(--custom-size-dot));
}

.custom-loader-3::after {
  left: calc(50% + 1.6vmin);
  animation-delay: -0.4s;
}

@keyframes custom-loader-3 {
  0%, 100% {
    transform: translateY(-2.6vmin);
  }
  
  44% {
    transform: translateY(2.6vmin);
  }
}

/* Loader 4 */
.custom-loader-4::before {
  height: var(--custom-size-bar);
  width: 6vmin;
  background-color: var(--custom-color);
  animation: custom-loader-4 0.8s cubic-bezier(0, 0, 0.03, 0.9) infinite;
}

@keyframes custom-loader-4 {
  0%, 44%, 88.1%, 100% {
    transform-origin: left;
  }
  
  0%, 100%, 88% {
    transform: scaleX(0);
  }
  
  44.1%, 88% {
    transform-origin: right;
  }
  
  33%, 44% {
    transform: scaleX(1);
  }
}

/* Loader 5 */
.custom-loader-5::before,
.custom-loader-5::after {
  height: 3vmin;
  width: var(--custom-size-bar);
  background-color: var(--custom-color);
  animation: custom-loader-5 0.6s cubic-bezier(0, 0, 0.03, 0.9) infinite;
}

.custom-loader-5::before {
  left: calc(50% - 1vmin);
  top: calc(50% - 3vmin);
}

.custom-loader-5::after {
  left: calc(50% + 1vmin);
  top: calc(50% - 1vmin);
  animation-delay: 0.2s;
}

@keyframes custom-loader-5 {
  0%, 88%, 100% {
    opacity: 0;
  }
  
  0% {
    transform: translateY(-6vmin);
  }
  
  33% {
    opacity: 1;
  }
  
  33%, 88% {
    transform: translateY(3vmin);
  }
}

/* Loader 6 */
.custom-loader-6::before {
  width: var(--custom-size-square);
  height: var(--custom-size-square);
  background-color: var(--custom-color);
  top: calc(50% - var(--custom-size-square));
  left: calc(50% - var(--custom-size-square));
  animation: custom-loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes custom-loader-6 {
  0%, 100% {
    transform: none;
  }
  
  25% {
    transform: translateX(100%);
  }
  
  50% {
    transform: translateX(100%) translateY(100%);
  }
  
  75% {
    transform: translateY(100%);
  }
}

/* Loader 7 */
.custom-loader-7::before,
.custom-loader-7::after {
  width: var(--custom-size-square);
  height: var(--custom-size-square);
  background-color: var(--custom-color);
}

.custom-loader-7::before {
  top: calc(50% - var(--custom-size-square));
  left: calc(50% - var(--custom-size-square));
  animation: custom-loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

.custom-loader-7::after {
  top: 50%;
  left: 50%;
  animation: custom-loader-7 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes custom-loader-7 {
  0%, 100% {
    transform: none;
  }
  
  25% {
    transform: translateX(-100%);
  }
  
  50% {
    transform: translateX(-100%) translateY(-100%);
  }
  
  75% {
    transform: translateY(-100%);
  }
}

/* Loader 8 */
.custom-loader-8::before,
.custom-loader-8::after {
  width: var(--custom-size-dot);
  height: var(--custom-size-dot);
  border-radius: 50%;
  background-color: var(--custom-color);
}

.custom-loader-8::before {
  top: calc(50% + 4vmin);
  animation: custom-loader-8-1 0.8s cubic-bezier(0.06, 0.01, 0.49, 1.18) infinite;
}

.custom-loader-8::after {
  opacity: 0;
  top: calc(50% - 2vmin);
  animation: custom-loader-8-2 0.8s cubic-bezier(0.46,-0.1, 0.27, 1.07) 0.2s infinite;
}

@keyframes custom-loader-8-1 {
  0%, 55%, 100% {
    opacity: 0;
  }
  
  0% {
    transform: scale(0.2);
  }
  
  22% {
    opacity: 1;
  }
  
  33%, 55% {
    transform: scale(1) translateY(-6vmin);
  }
}

@keyframes custom-loader-8-2 {
  0%, 100% {
    opacity: 0;
  }
  
  33% {
    opacity: 0.3;
  }
  
  0% {
    transform: scale(0);
  }
  
  100% {
    transform: scale(4);
  }
}

/* Loader 9 */
.custom-loader-9::before,
.custom-loader-9::after {
  width: var(--custom-size-dot);
  height: var(--custom-size-dot);
  border-radius: 50%;
  background-color: var(--custom-color);
  animation: custom-loader-9 0.42s cubic-bezier(0.39, 0.31, 0, 1.11) infinite;
}

.custom-loader-9::before {
  left: calc(50% - var(--custom-size-dot) - 1.6vmin);
}

.custom-loader-9::after {
  left: calc(50% + 1.6vmin);
  animation-delay: 0.12s;
}

@keyframes custom-loader-9 {
  0%, 100% {
    opacity: 0;
  }
  
  0% {
    transform: translate(-4vmin, -4vmin);
  }
  
  66% {
    opacity: 1;
  }
  
  66%, 100% {
    transform: none;
  }
}