@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  width: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Mokoto';
  src: url('/public/fonts/Mokoto.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face{
  font-family: 'Inter-Bold.woff';
  src: url('/public/fonts/Inter-Bold.woff') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Inter-Regular.woff';
  src: url('/public/fonts/Inter-Regular.woff') format('truetype');
  font-weight: normal;
  font-style: normal;  
}