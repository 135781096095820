@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
.blinking-dots {
animation: blink 1.5s infinite;
}